import React from "react";

const Header: React.FC = () => {
  return (
    <header style={{ gridArea: "header" }}>
      <h1>Alexandre Silva</h1>
      <p>
        The purpose of this website will be to provide some information about
        me, and at the same time, also be my personal playground.
      </p>
    </header>
  );
};

export default Header;
