import React from "react";
import CV from "../CV/CV";
import Github from "../Social/Github";
import Linkedin from "../Social/Linkedin";
import css from "./Footer.module.scss";

const Footer: React.FC = () => {
  return (
    <footer className={css.footer}>
      <Github
        username="Alexandre Silva - Github"
        url="https://github.com/alexandre-dasilva"
      />
      <Linkedin
        username="Alexandre Silva - Linkedin"
        url="https://www.linkedin.com/in/alexer99/"
      />
      <a
        href="/files/CV_AlexandreSilva.pdf"
        target="_blank"
        rel="noreferrer"
        aria-label="CV"
      >
        <CV />
      </a>
    </footer>
  );
};

export default Footer;
