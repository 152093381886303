import React from "react";
import Footer from "./Components/Footer/Footer";
import Header from "./Header";
import underConstruction from "./underconstruction.gif";

const App: React.FC = () => {
  return (
    <>
      <Header />
      <main style={{ gridArea: "main" }}>
        <img src={underConstruction} alt="Under construction" />
      </main>
      <Footer />
    </>
  );
};

export default App;
